<template>
  <div>
    <div class="text-container">
      <p>
        Nutzen Sie unsere Erfahrungen, die wir in den zurückliegenden Jahren mit
        Kommissionier-Automaten gesammelt haben. Da unsere Apotheken alle
        automatisiert waren und wir uns mit diesen Themen intensiv beschäftigt
        haben, konnten wir Kenntnisse vom Markt und Anbietern sammeln.
      </p>
      <p>
        Bei dem Thema Kommissionier-Automaten arbeiten wir mit unserem Partner
        <a href="https://www.ami-foerdertechnik.de/"
          >AMI Förder- und Lagertechnik GmbH</a
        >
        zusammen.
      </p>
      <video-player video="ami-automat" />
    </div>
  </div>
</template>

<script>
import VideoPlayer from "../../components/VideoPlayer.vue";

export default {
  components: {
    VideoPlayer,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten Sie bei der Anschaffung und Einführung von Kommissionierautomaten für Ihre Apotheke.",
      },
    ],
  },
};
</script>