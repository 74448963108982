<template>
  <div aspect="16by9" class="w-100">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>


<script>
import "../plugins/video-js.css";
import videojs from "../plugins/video.js";

export default {
  name: "VideoPlayer",
  props: ["video", "autoplay"],
  data() {
    return {
      loading: true,
      player: null,
    };
  },
  mounted() {
    console.log(videojs, typeof videojs);
    this.player = videojs(this.$refs.videoPlayer, {
      autoplay: true,
      muted: true,
      controls: true,
      fluid: true,
      responsive: true,
      sources: [
        {
          src: "/vod/" + this.$props.video + "/playlist.m3u8",
          type: "application/x-mpegURL",
        },
      ],
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>